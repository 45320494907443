<template>
	<div class="page_container">
		<div class="page_content">
			<div class="page_title_bg">
				<div class="page_title_flag" />
				<div class="page_title">产品服务</div>
			</div>
			<div class="page_prod">
				<a target="_self" href="http://fininfo.gmgft.com/app/app.html" style="width: 100%; height: 100%;">
					<img src="@/assets/images/12131772.jpg" alt="产品与服务-APPintro" title="产品与服务-APPintro" id="img_smv_con_32_57" style="width: 1192px; height: 1341px;">
				</a>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'News',
	data() {
	    return {};
	},
	mounted() {
	
	},
	methods: {},
}
</script>

<style lang="scss" scoped>
.page_container{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	.page_content{
		margin-top: 80px;
		flex-grow: 1;
		width: 1200px;
		align-self: center;
		.page_title_bg{
			margin-top: 32px;
			display: flex;
			align-items: center;
			.page_title_flag{
				margin-right: 5px;
				width: 22px;
				height: 64px;
				background-color: #409eff;
			}
			.page_title{
				font-size: 42px;
				color:#444444; 
				font-family:Microsoft YaHei;
				font-weight: bold;
			}
		}
		.page_prod{
			margin: 20px 0 30px;
		}
	}
}
</style>